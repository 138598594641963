<template>
  <div class="huahu-kids-container" id="page-container">
    <navbar-interactive rootClassName="navbar-interactive-root-class-name3"></navbar-interactive>
    <div class="huahu-kids-frame">
      <div class="huahu-kids-page-content">
        <div class="huahu-kids-hero-image">
          <div class="huahu-kids-logo-description">
            <div class="huahu-kids-description">
              <img
                alt="image"
                src="/logos/Solart3d.svg"
                class="huahu-kids-image"
              />
              <div class="title_container">
                <span class="huahu-kids-text">Immersive Digital Interactive Entertainment</span>
                <span class="huahu-kids-text01">
                  AIGC·Integration of Science and Art Innovation
                </span>
              </div>
            </div>
          </div>
          <div class="huahu-kids-mottos">
            <img
              alt="bottomArrow6397"
              src="/bottomarrow.svg"
              class="huahu-kids-bottom-arrow"
            />
          </div>
        </div>
        <div>
          <a href="https://store.steampowered.com/app/2568060/Interstellar_Shore_VR_I_LIVE/" target="_blank">
            <product-introduction-cardv2
              image_src="/Immersive1.png"
              mainTitle="Research on Holographic Audio-Visual Optics"
              subTitle="Leveraging naked-eye 3D holographic hardware and proprietary algorithms, we conduct research in both sound and optical sciences to break through technological barriers in the audio-visual industry, creating astonishing sensory experiences. Our naked-eye 3D holographic hardware boasts an expansive field of view and high-definition 3D effects, visible without any aids. This service opens up new possibilities for industries such as advertising, entertainment, and cultural creativity, enhancing brand marketing impact and captivating audiences, thus helping businesses attract their target audience and craft memorable and distinctive images."
            ></product-introduction-cardv2>
          </a>
          <product-introduction-cardv2
            reverse
            image_src="Immersive2.png"
            mainTitle="Resort V 2027 AIGC Game"
            subTitle="An open-world metaverse game, created and supervised by our co-founder, Yihua Liu. This game ingeniously utilizes a multitude of advanced Artificial Intelligence Generated Content (AIGC) technologies, and it also perfectly integrates AI technology with the storyline, adding infinite possibilities to the narrative. We are committed to crafting a unique and distinctive personalized gaming experience for each player, ensuring that every exploration and interaction in this world of creativity and possibilities is filled with surprises and novelty. Every story is a unique journey exclusive to the player."
          ></product-introduction-cardv2>
          <product-introduction-cardv2
              image_src="/Immersive3.png"
              mainTitle="Immersive Digital Expo"
              subTitle="SOLART TECH SOLART TECH took part in the Expo themed as “the Beauty of Technology in Digital Cultural Tourism” in the Beijing Design and Art Expo 2023, which showcases a space for experiencing the new generation of digital aesthetics.Jointly with many digital creative teams and artists, utilizing AI technology as a foundation and employing various mediums such as SOLART naked-eye 3D holography, interactive sensory technology, mapping projection, and augmented reality (AR), we craft an extraordinarily futuristic art journey by integrating a variety of digital art creation techniques, and innovating diversified forms of artistic expression."
            ></product-introduction-cardv2>
          <product-introduction-cardv2
            reverse
            image_src="Immersive4.png"
            mainTitle="Immersive Light and Shadow Night Tour"
            subTitle='Teaming up with "Deep Forest Exploration" to create the "Immersive Light and Shadow Night Tour" digital technology art project. Through digital multimedia and interactive installations, we integrate nature, culture, and technology to provide visitors with an immersive experience of light and shadow exploration through innovative and diverse artistic expressions. You&apos;ll find yourself immersed in a fantasy "forest realm" woven by music, lighting, projections, and the latest AIGC technology, awakening deeper sensory awareness and experiencing the mystery and beauty of nature in an immersive way, feeling the unique charm of interwoven light and shadow.<br/>Location: No.235 South Fourth Ring Middle Road, Fengtai District, Beijing'
          ></product-introduction-cardv2>
          <product-introduction-cardv2
            mainTitle='AI magic painting camera-"HUAHUA HUB" Creative Aesthetics Image Output'
            subTitle="With SOLART TECH's self-developed AIGC visual art technology as the core program, the camera instantly creates art portraits from your own photos on the spot, realizing a creative aesthetic effect output that is far superior to that of traditional filters. It made its debut at the immersive light and shadow art exhibition “Monet and His Friends”crafted by SOLART TECH in Bangkok, Thailand in 2013,which attracted more than 10,000 visitors to experience and take photos in just two weeks, attracting widespread attention at home and abroad."
            image_src="/Placeholder_huahua.png"
          ></product-introduction-cardv2>
        </div>
        <div class="huahu-kids-top-navigation-bar">
          <div class="huahu-kids-top-navigation-bar-left-logo">
            <img
              alt="logo6457"
              src="/logo%201-1500h.png"
              class="huahu-kids-logo"
            />
          </div>
          <bottom-bar></bottom-bar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarInteractive from '../components/navbar-interactive'
import AppBtndefault from '../components/btndefault'
import ProductIntroductionCardv2 from '../components/product-introduction-cardv2'
import productCarousel from '../components/productCarousel.vue'
import BottomBar from '../components/bottomBar.vue'

export default {
  name: 'immersiveDigital',
  components: {
    NavbarInteractive,
    AppBtndefault,
    ProductIntroductionCardv2,
    productCarousel,
    BottomBar
  },
  data(){
    BottomBar
    return{}
  },
  metaInfo: {
    title: 'Immersive Interactive Entertainment - SOLART素鳞科技',
    meta: [
      {
        property: 'og:title',
        content: 'Immersive Interactive Entertainment - SOLART素鳞科技',
      },
    ],
  },
}
</script>

<style scoped>
.home-text06 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  opacity: 0.80;
  font-size: 24px;
  font-style: 85 Bold;
  text-align: justified;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 33.599998474121094px;
  font-stretch: normal;
  text-decoration: none;
}
.huahu-kids-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.huahu-kids-frame {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(26, 26, 26, 1);
}
.huahu-kids-page-content {
  gap: 39px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  min-height: 100vh;

}
.huahu-kids-hero-image {
  gap: 10px;
  height: 900px;
  display: flex;
  padding: 22px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0.00%,rgba(0, 0, 0, 0.4) 100.00%),url("/immersiveDigital.png");
  background-position-x: 32%;
}
.huahu-kids-logo-description {
  gap: 40px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.huahu-kids-description {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.huahu-kids-image {
  width: 160px;
  height: 160px;
  object-fit: cover;
}
.huahu-kids-text {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 64px;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 64px;
  font-stretch: normal;
  text-decoration: none;
}
.huahu-kids-text01 {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 2rem;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 36px;
  font-stretch: normal;
  letter-spacing: 12px;
  text-decoration: none;
}
.title_container{
  display: flex; 
  flex-direction: column;
  gap: 18px;
  max-width: 95vw;
}
@media (max-width: 956px) {
  .huahu-kids-text {
    font-size: 48px !important;
    line-height: 38px !important;
  }
  .huahu-kids-text01{
    font-size: 20px !important;
    line-height: 6px;
    letter-spacing:normal !important;
  }
  .title_container{
  display: flex; 
  flex-direction: column;
  gap: 2px;
}

}
@media (max-width: 541px) {
  .huahu-kids-text {
    font-size: 30px !important;
    line-height: 38px !important;
  }
  .huahu-kids-text01{
    font-size: 20px !important;
    line-height: 6px;
    letter-spacing:normal !important;
  }
  .title_container{
  display: flex; 
  flex-direction: column;
  gap: 2px;
}

}

@media (max-width: 374px) {
  .huahu-kids-text {
    font-size: 26px !important;
    line-height: 38px !important;
  }
  .huahu-kids-text01{
    font-size: 20px !important;
    line-height: 6px;
    letter-spacing:normal !important;
  }
  .title_container{
  display: flex; 
  flex-direction: column;
  gap: 2px;
}

}

.huahu-kids-mottos {
  gap: 9px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
}
.huahu-kids-bottom-arrow {
  width: 16px;
  height: 8px;
}
.huahu-kids-top-navigation-bar {
  gap: 10px;
  display: flex;
  padding: 10px 30px 25px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.huahu-kids-top-navigation-bar-left-logo {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.huahu-kids-logo {
  width: 250px;
  height: 64px;
}
.huahu-kids-top-navigation-bar-right-links {
  gap: 38px;
  display: flex;
  opacity: 0.80;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.huahu-kids-text05 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.huahu-kids-text07 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.huahu-kids-text09 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 991px) {
  .huahu-kids-text {
    color: rgb(255, 255, 255);
    font-size: 64px;
    font-weight: 700;
    line-height: 64px;
    text-decoration: none;
  }
  .huahu-kids-text01 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 12px;
    text-decoration: none;
  }
  .huahu-kids-text02 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 12px;
    text-decoration: none;
  }
  .huahu-kids-text03 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 12px;
    text-decoration: none;
  }
  .huahu-kids-text04 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 12px;
    text-decoration: none;
  }
}
@media(max-width: 767px) {
  .huahu-kids-text01 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .huahu-kids-text02 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .huahu-kids-text03 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .huahu-kids-text04 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .huahu-kids-logo {
    width: auto;
    height: 2rem;
  }
  .huahu-kids-text05 {
    font-size: 16px;
  }
  .huahu-kids-text07 {
    font-size: 16px;
  }
  .huahu-kids-text09 {
    font-size: 16px;
  }
}
</style>
