<template>
  <div class="home-container" id="page-container">
    <navbar-interactive rootClassName="navbar-interactive-root-class-name"></navbar-interactive>
    <div class="home-frame">
      <div class="home-page-content">
        <div class="home-hero-image">
          <div class="home-logo-description">
            <img alt="Logo6389" src="/external/t1-200h.png" class="home-logo" />
            <div class="home-description">
              <span class="home-text">
                <span>An Innovative Integrated Institute in 3D Ecosystem and AI Computer Vision</span>
              </span>
            </div>
          </div>
          <div class="home-mottos">
            <div class="home-motto-en">
              <span class="home-text04">
                <span>Generate the future Imagine Infinite</span>
              </span>
            </div>
          </div>
          <img
            alt="bottomArrow6397"
            src="/bottomarrow.svg"
            class="home-bottom-arrow"
          />  
        </div>
		<div class="home-video"><video src="../../public/solart.mp4" width="100%" height="auto" muted autoplay preload loop x5-playsinline playsinline webkit-playsinline></video></div>
		<div class="home-product-introduction">
          <div class="home-product-introduction-title">
            <span class="home-text06"><span>PRODUCTS</span></span>
          </div>
          <div class="home-prodcut-carousel">
            <router-link to="/huahua" class="home-navlink">
              <product-card 
                class="home-component01" 
                CardCover_src="/huahuaCover.png" 
                Logo_Src="/logos/huahua.svg"
                CardHeader="AIGC Image Generation Platform"
                CardDescription="HUAHUA"
              ></product-card>
            </router-link>
            <router-link to="/3dchuangyi" class="home-navlink1">
              <product-card
                Logo_Src="/logos/Solart3d.svg"
                CardHeader="3D Digital Asset Aggregation Platform"
                CardDescription="ARTOL 3D"
                CardCover_src="/solart3d.png"
                class="home-component02"
              ></product-card>
            </router-link>
            <router-link to="/huahuakids" class="home-navlink2">
              <product-card
                Logo_Src="/logos/huahuakids.svg"
                CardHeader="HUAHUA Kids AI STUDIO"
                CardDescription="AIGC Education"
                CardCover_src="/huahuakids.png"
                class="home-component03"
              ></product-card>
            </router-link>
            <router-link to="/immersive" class="home-navlink2">
              <product-card
                CardHeader="Integration of Technology and Art Innovation"
                CardDescription="Immersive Interactive Entertainment"
                CardCover_src="/immersiveDigital.png"
                class="home-component03"
              ></product-card>
            </router-link>
          </div>
        </div>
        <div class="home-horizontal-banner-group"  v-if="pageData!=null">
        <!-- construct app-homepagedescription  using pageData  -->
        <app-homepagedescription
      v-for="(item, index) in pageData.description"
      :key="item.idex"
      :title="item.title"
      :description="item.description"
      :image_src="item.image"
      :reverseOrderData="index % 2 === 1"
    ></app-homepagedescription>
        </div>
        <div class="home-roadmap">
          <div class="home-roadmaptitle">
            <span class="home-text53"><span>Development Route</span></span>
          </div>
          <br>
          <img
            alt="roadmapimage6440"
            src="/200h.png"
            class="home-roadmapimage"
          />
        </div>
        <div class="home-team" v-if="pageData!=null">
          
          <div class="home-teamcontent">
            <div class="home-teamtitle">
            <span class="home-text53"><span>R&D Team</span></span>
          </div>
            <div class="home-container1">
              <div class="home-title">
                <span class="home-text27"><span>Founding Team</span></span>
                <underline-block></underline-block>
              </div>
              <div class="home-teammember-list">
                <name-card0 v-for="item in pageData.staff" v-bind:key="item.key"  v-bind:name="item.name" v-bind:description="item.description" v-bind:namecard_avatar="item.avartar"></name-card0>

              </div>
              <div class="home-title">
                <span class="home-text27"><span>Research Team</span></span>
                <underline-block></underline-block>
              </div>
              <div class="home-teammember-list">
                <name-card0 v-for="item in pageData.researcher" v-bind:key="item.key"  v-bind:name="item.name" v-bind:description="item.description" v-bind:namecard_avatar="item.avartar"></name-card0>

              </div>
              </div>
              <router-link to="/about" class="home-navlink3">
              <app-btndefault class="home-component10" text="More"></app-btndefault>
            </router-link>
            </div>
            
          </div>
          <div class="home-top-navigation-bar">
          <div class="home-top-navigation-bar-left-logo">
            <img alt="logo6457" src="/logo%201-1500h.png" class="home-logo1" />
          </div>
          <BottomBar></BottomBar>
        </div>
        </div>

      </div>
    </div>
</template>

<script>
import NavbarInteractive from '../components/navbar-interactive'
import ProductCard from '../components/product-card'
import NameCard0 from '../components/name-card0'
import AppBtndefault from '../components/btndefault'
import AppHomepagedescription from '../components/homepagedescription'
import BottomBar from '../components/bottomBar.vue'
import underlineBlock from '../components/underlineBlock.vue'


export default {
  name: 'Home',
  components: {
    NavbarInteractive,
    ProductCard,
    NameCard0,
    AppHomepagedescription,
    AppBtndefault,
    BottomBar,
    underlineBlock
},

  metaInfo: {
    title: 'SOLART素鳞科技',
    meta: [
      {
        property: 'og:title',
        content: 'SOLART素鳞科技',
      },
    ],
  },
  data() {
  return {
    dataUrl: "/homepage.json",
    pageData: null,
  };
},
mounted() {
  // Use fetch to get data from the mock API
  fetch(this.dataUrl)
    .then((res) => res.json())
    .then((data) => {
      this.pageData = data;
      //console.log(this.pageData); // Make sure the data is available here
    });
},
}
</script>

<style scoped>
.home-team {
  gap: 40px;
  width: 100%;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.home-teamtitle {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-text25 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  opacity: 0.80;
  font-size: 24px;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 33.599998474121094px;
  font-stretch: normal;
  text-decoration: none;
}
.home-teamcontent {
  gap: 10px;
  display: flex;
  padding: 30px 0;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  /* background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0.00%,rgba(0, 0, 0, 0.6) 100.00%),url("/compress_placeholder%202-1500w.jpg"); */
}
.home-container1 {
  gap: 10px;
  display: flex;
  padding: 0 82px;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 514px) {
  .home-container1 {
    padding: 0 10px;
  }
}
  

.home-title {
  gap: 4px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.home-text27 {
  color: rgba(255, 255, 255, 1);
  width: 104px;
  height: auto;
  font-size: 24px;
  font-style: Regular;
  text-align: justified;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 33.599998474121094px;
  font-stretch: normal;
  text-decoration: none;
}
.home-rectangle2 {
  width: auto;
  height: 4px;
  align-self: stretch;
  border-radius: 10px;
}
.home-teammember-list {
  gap: 40px;
  display: flex;
  padding: 30px 33px;
  overflow: hidden;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  
}
.home-name-card0 {
  gap: 47px;
  display: flex;
  align-items: center;
}
.home-avatar {
  gap: 10px;
  display: flex;
  align-items: center;
}
.home-ellipse1 {
  width: 120px;
  height: 120px;
}
.home-namedescription {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text29 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 24px;
  font-style: 85 Bold;
  text-align: left;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 33.599998474121094px;
  font-stretch: normal;
  text-decoration: none;
}
.home-text31 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 14px;
  font-style: 85 Bold;
  text-align: left;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 19.600000381469727px;
  font-stretch: normal;
  text-decoration: none;
}
.home-container2 {
  gap: 10px;
  display: flex;
  padding: 0 82px;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-container {
  z-index: 0;
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-frame {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(26, 26, 26, 1);
}
.home-page-content {
  gap: 39px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  min-height: 100vh;
}
.home-hero-image {
  gap: 10px;
  height: 900px;
  display: flex;
  padding: 22px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0.00%,rgba(0, 0, 0, 0.4) 100.00%),url("/Placeholder.png");
  background-position:left;
  background-size: cover;
}
.heroImageHover{
  /* image grow slightly */
  background-size:cover;
}
.home-logo-description {
  gap: 40px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-logo {
  width: 50vw;
  height: auto;
  max-width: 843px;
}
.home-description {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.home-text {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 40px;
  
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 56px;
  font-stretch: normal;
  text-decoration: none;
}
@media (max-width: 563px) {
  .home-text {
    font-size: 25px;
    line-height: 42px;
  }
}
  

.home-mottos {
  gap: 9px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
}
.home-motto-cn {
  gap: 10px;
  display: flex;
  align-items: center;
}
.home-text02 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 26px;
  font-style: 55 Regular;
  text-align: justified;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 400;
  line-height: 50.400001525878906px;
  font-stretch: normal;
  text-decoration: none;
}
.home-motto-en {
  gap: 10px;
  display: flex;
  align-items: center;
}
.home-text04 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 20px;
  font-style: 55 Regular;
  text-align: justified;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 400;
  line-height: 33.599998474121094px;
  font-stretch: normal;
  text-decoration: none;
}
@media  (max-width: 340px) {
  .home-text04 {
    font-size: 16px;
    line-height: 28px;
  }
}

.home-bottom-arrow {
  width: 16px;
  height: 8px;
}
.home-video {
	width: 100%;
	padding: 0 0 10px;
}
.home-product-introduction {
  gap: 33px;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.home-product-introduction-title {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-text06 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  opacity: 0.80;
  font-size: 24px;
  font-style: 85 Bold;
  text-align: justified;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 33.599998474121094px;
  font-stretch: normal;
  text-decoration: none;
}
.home-prodcut-carousel {
  gap: 20px;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  padding: 30px;
}
@media(max-width: 986px) {
  .home-prodcut-carousel {
    padding: 0;
  }
}
  

.home-navlink {
  display: contents;
}
.home-component01 {
  text-decoration: none;
}
.home-navlink1 {
  display: contents;
}
.home-component02 {
  text-decoration: none;
}
.home-navlink2 {
  display: contents;
}
.home-component03 {
  text-decoration: none;
}
.home-horizontal-banner-group {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.home-first {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #000000;
}
.home-image {
  width: 50%;
  height: 326px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-list {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text08 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 36px;
  font-style: 85 Bold;
  text-align: justified;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 50.400001525878906px;
  font-stretch: normal;
  text-decoration: none;
}
.home-text10 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  font-style: 65 Medium;
  text-align: left;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 500;
  line-height: 26px;
  font-stretch: normal;
  text-decoration: none;
}
.home-first1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #000000;
}
.home-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-list1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text23 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 36px;
  font-style: 85 Bold;
  text-align: justified;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 50.400001525878906px;
  font-stretch: normal;
  text-decoration: none;
}
.home-text25 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  font-style: 65 Medium;
  text-align: left;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 500;
  line-height: 26px;
  font-stretch: normal;
  text-decoration: none;
}
.home-image1 {
  width: 50%;
  height: 326px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-first2 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #000000;
}
.home-image2 {
  width: 50%;
  height: 326px;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-list2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text38 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 36px;
  font-style: 85 Bold;
  text-align: justified;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 50.400001525878906px;
  font-stretch: normal;
  text-decoration: none;
}
.home-text40 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 16px;
  font-style: 65 Medium;
  text-align: left;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 500;
  line-height: 26px;
  font-stretch: normal;
  text-decoration: none;
}
.home-roadmap {
  gap: 10px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .home-roadmap {
    display: none;
  }
}
  

.home-roadmaptitle {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-text53 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  opacity: 0.80;
  font-size: 24px;
  font-style: 85 Bold;
  text-align: justified;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 33.599998474121094px;
  font-stretch: normal;
  text-decoration: none;
}
.home-roadmapimage {
  width: auto;
  height: auto;
  align-self: stretch;
  margin-left: 40px;
  margin-right: 40px;
}
@media screen and (max-width: 991px) {
  .home-roadmapimage {
    width: 100%;
    height: auto;
    align-self: stretch;
    margin-left: 0px;
    margin-right: 0px;
  }
}
  

.home-team {
  gap: 40px;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.home-teamtitle {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-text55 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  opacity: 0.80;
  font-size: 24px;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 33.599998474121094px;
  font-stretch: normal;
  text-decoration: none;
}
.home-teamcontent {
  gap: 10px;
  display: flex;
  padding: 30px 0;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0.00%,rgba(0, 0, 0, 0.6) 100.00%),url("/Placeholder 1 (6).png");
}
.home-teammember-list {
  gap: 40px;
  display: flex;
  padding: 30px 33px;
  overflow: hidden;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.home-navlink3 {
  display: contents;
}
.home-component10 {
  text-decoration: none;
}
.home-top-navigation-bar {
  gap: 10px;
  display: flex;
  padding: 10px 30px 25px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.home-top-navigation-bar-left-logo {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}  

.home-logo1 {
  width: 250px;
  height: 64px;
}

@media(max-width: 991px) {
  .home-first {
    flex-direction: column;
  }
  .home-image {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-content {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-first1 {
    flex-direction: column-reverse;
  }
  .home-content1 {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-image1 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-first2 {
    flex-direction: column;
  }
  .home-image2 {
    flex: 0 0 auto;
    width: 100%;
  }
  .home-content2 {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .home-logo1 {
	width: auto;
	height: 2em;
  }
  .home-content {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-content1 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-content2 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-roadmapimage {
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media(max-width: 479px) {
  .home-list {
    gap: var(--dl-space-space-unit);
  }
  .home-list1 {
    gap: var(--dl-space-space-unit);
  }
  .home-list2 {
    gap: var(--dl-space-space-unit);
  }
}
</style>
