<template>
  <div class="solart3d-container" id="page-container">
    <navbar-interactive
            rootClassName="navbar-interactive-root-class-name5"
          ></navbar-interactive>
    <div class="solart3d-frame">
      <div class="solart3d-page-content">
        <div class="solart3d-hero-image">

          <div class="solart3d-logo-description">
            <div class="solart3d-description">
              <img
                alt="image"
                src="/logos/Solart3d.svg"
                class="solart3d-image"
              />
              <div class="title_container">
                <span class="solart3d-text">ARTOL 3D</span>
              <span class="solart3d-text1">Build your virtual world in one step</span>
              </div>

              <a
                href="http://3d.solart.pro/"
                target="_blank"
                rel="noreferrer noopener"
                class="solart3d-link"
              >
                <app-btndefault
                  text="ENTER"
                  rootClassName="btndefault-root-class-name2"
                  class="solart3d-component1"
                ></app-btndefault>
              </a>
            </div>
          </div>
          <div class="solart3d-mottos">
            <img
              alt="bottomArrow6397"
              src="/bottomarrow.svg"
              class="solart3d-bottom-arrow"
            />
          </div>
        </div>
        <div>
          <product-introduction-cardv2
            image_src="/Placeholder 1 (8).png"
            mainTitle="One-stop 3D model database platform"
            subTitle="SOLART 3D provides a sustainable and reliable infrastructure for metaverse builders and digital visualization application professionals in different industries, and an ecosystem in the field of 3D digital assets. Component assets covering all production stages in 3D visual directions, including games, animation, film, virtual performances, virtual live streaming, metaverse builders, and other specialized industries such as aerospace, medical, architecture, etc. Creators can build and customize on top of the best models to create 3D worlds more easily and quickly. SOLART is also a large-scale 3D model machine learning database to meet the future needs and goals of 3D AIGC artificial intelligence modeling."          
          ></product-introduction-cardv2>
          <product-introduction-cardv2
            reverse
            image_src="/3dchuangyi2.png"
            mainTitle="Research on Image Dimensionality Enhancement: Converting 2D Images into 3D Holographic Images"
            subTitle="SOLART's research on image dimensionality enhancement is based on naked-eye 3D holographic hardware devices, exploring algorithms to convert 2D images into 3D optical images. This enables the transformation of multi-angle images of individuals, scenes, etc., captured by devices such as smartphones, cameras, drones, into 3D optical models, directly displayed on naked-eye 3D holographic hardware devices."          
          ></product-introduction-cardv2>
        </div>
        <span class="home-text06 hidden"><span>产品特色</span></span>
        <product-carousel class="hidden" :image_src="caresouleImgs"></product-carousel>
        <div class="solart3d-top-navigation-bar">
          <div class="solart3d-top-navigation-bar-left-logo">
            <img alt="logo6457" src="/logo%201-1500h.png" class="solart3d-logo" />
          </div>
          <bottom-bar></bottom-bar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarInteractive from '../components/navbar-interactive'
import AppBtndefault from '../components/btndefault'
import ProductIntroductionCardv2 from '../components/product-introduction-cardv2'
import productCarousel from '../components/productCarousel.vue'
import BottomBar from '../components/bottomBar.vue'

export default {
  name: 'SOLART3D',
  components: {
    NavbarInteractive,
    AppBtndefault,
    ProductIntroductionCardv2,
    productCarousel,
    BottomBar,

  },
  data(){
    return{
      caresouleImgs: [
        '/exampleSlide1.png',
        '/exampleSlide2.png',
        '/exampleSlide3.png',
      ],
    }
  },
  metaInfo: {
    title: 'ARTOL 3D - SOLART素鳞科技',
    meta: [
      {
        property: 'og:title',
        content: 'ARTOL 3D - SOLART素鳞科技',
      },
    ],
  },
}
</script>

<style scoped>
.home-text06 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  opacity: 0.80;
  font-size: 24px;
  font-style: 85 Bold;
  text-align: justified;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 33.599998474121094px;
  font-stretch: normal;
  text-decoration: none;
}
.solart3d-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.solart3d-frame {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(26, 26, 26, 1);
}
.solart3d-page-content {
  gap: 39px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  min-height: 100vh;

}
.solart3d-hero-image {
  gap: 10px;
  height: 900px;
  display: flex;
  padding: 22px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0.00%,rgba(0, 0, 0, 0.4) 100.00%),url("/solart3d.png");
  background-position: center;
}
.solart3d-logo-description {
  gap: 40px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.solart3d-description {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.solart3d-image {
  width: 160px;
  height: 160px;
  object-fit: cover;
}
.title_container{
  display: flex; 
  flex-direction: column;
  gap: 18px;
}
.solart3d-text {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 64px;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 64px;
  font-stretch: normal;
  text-decoration: none;
}
.solart3d-text1 {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 2rem;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 36px;
  font-stretch: normal;
  letter-spacing: 12px;
  text-decoration: none;
}

@media (max-width: 606px) {
  .solart3d-text {
    font-size: 32px !important;
    line-height: 38px !important;
  }
  .solart3d-text1{
    font-size: 20px !important;
    line-height: 6px;
    letter-spacing:normal !important;
  }
  .title_container{
  display: flex; 
  flex-direction: column;
  gap: 2px;
}
}
.solart3d-link {
  display: contents;
}
.solart3d-component1 {
  text-decoration: none;
}
.solart3d-mottos {
  gap: 9px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
}
.solart3d-bottom-arrow {
  width: 16px;
  height: 8px;
}
.solart3d-top-navigation-bar {
  gap: 10px;
  display: flex;
  padding: 10px 30px 25px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.solart3d-top-navigation-bar-left-logo {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.solart3d-logo {
  width: 250px;
  height: 64px;
}
.solart3d-top-navigation-bar-right-links {
  gap: 38px;
  display: flex;
  opacity: 0.80;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.solart3d-text2 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.solart3d-text4 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.solart3d-text6 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 991px) {
  .solart3d-text {
    color: rgb(255, 255, 255);
    font-size: 64px;
    font-weight: 700;
    line-height: 64px;
    text-decoration: none;
  }
  .solart3d-text1 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 12px;
    text-decoration: none;
  }
}
@media(max-width: 767px) {
  .solart3d-text1 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .solart3d-logo {
    width: auto;
    height: 2rem;
  }
  .solart3d-text2 {
    font-size: 16px;
  }
  .solart3d-text4 {
    font-size: 16px;
  }
  .solart3d-text6 {
    font-size: 16px;
  }
}
</style>
