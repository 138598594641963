<template>
    <div class="news-details-container" id="page-container">
        <navbar-interactive v-bind:defaultBlack="true"></navbar-interactive>
        <div class="news-details-frame">
            <div class="news-details-page-content">
                <div class="news-details-hero-image"></div>
                <div class="news-details-news-container">
                    <div class="news-details-frame13">
                        <span class="news-details-text">
                            Private Policy
                        </span>
                        <div class="content">
                            <p><u style="text-indent: 0em;">F</u><u style="text-indent: 0em;">oreword</u></p>
                            <p><u></u></p>
                            <p>&nbsp;</p>
                            <p align="justify">Beijing Solart&nbsp;Technology Industry Co., Ltd. (hereinafter referred to as
                                "we") attaches great importance to your privacy and personal information protection. When
                                you access our virtual booth, we may collect and use your relevant information. Through this
                                privacy policy, we hope to explain to you how we collect, use, store, and share this
                                information when you participate in the online exhibition, as well as the ways we provide
                                for you to access, update, control, and protect this information.</p>
                            <p align="justify">We deeply understand the importance of personal information to you and will
                                do our utmost to ensure the security and reliability of your personal information. We are
                                committed to maintaining your trust in us. At the same time, we promise that we will adopt
                                corresponding security measures in accordance with mature security standards in the industry
                                to protect your personal information.</p>
                            <p align="justify">Please carefully read and understand this privacy policy before using our
                                services. If you do not agree with the contents of this privacy policy, it will result in us
                                being unable to provide you with complete services. We also ask you to immediately stop
                                using our services. Your use or continued use of the services we provide indicates that you
                                agree to our collection, use, storage, sharing, disclosure, and protection of your personal
                                information in accordance with this policy. If you have any questions or concerns about how
                                we collect and process your personal information, you can contact us through the contact
                                information in the "How to Contact Us" section of this privacy policy.</p>
                            <p align="justify">&nbsp;</p>
                            <p align="justify"><strong><u>What information do we collect?</u></strong></p>
                            <p align="justify">When you register to participate in our virtual booth, we may directly
                                collect your personal information from you.</p>
                            <p align="justify">If you register for our virtual booth&nbsp;as a visitor through the website,
                                we may collect your precise location to identify or verify user identity and for access
                                control purposes.</p>
                            <p align="justify">&nbsp;</p>
                            <p align="justify"><strong><u>Usage and Purposes of Personal Information</u></strong></p>
                            <p align="justify"><strong><em>
                                        <font face="Times New Roman">Virtual </font>
                                    </em></strong><strong><em>Exhibition and Event
                                        Management</em></strong><strong><em>&nbsp;&nbsp;<font face="Times New Roman">Purpose
                                        </font></em></strong></p>
                            <p align="justify">If you participate in the exhibition as a speaker, sponsor, exhibitor, or
                                visitor, we will use your personal information in the management of the exhibition. This
                                personal information also helps us in logistical management and analysis of the event to
                                ensure smooth running and to provide a better experience for our customers.</p>
                            <p align="justify">Additionally, we will capture photographs and videos during the event, where
                                attendees, speakers, sponsors, or exhibitors may be featured. Within the scope permitted by
                                applicable data protection laws, if you are captured in the photographs and videos taken at
                                our events as an attendee, sponsor, or exhibitor, we may use these photographs and videos
                                for promotional purposes. Third parties may also take photographs at our events, for
                                example, to document the booths they have visited or exhibitors they have met. We are not
                                responsible for the collection or use of images captured by third-party cameras.</p>
                            <p align="justify">If the exhibition and event use attendee badge scanning technology, we will
                                use your personal information to identify your identity at certain entry points of the
                                exhibition, such as VIP areas, seminar venues, access points, and other areas. In such
                                cases, we may acquire certain location information. Attendee badges and exhibitor
                                registration QR codes cannot be remotely read.</p>
                            <p align="justify">Personal information obtained through attendee badges or exhibitor
                                registration QR code scanning will be used for entrance control, security, and analysis of
                                attendee traffic to improve the exhibition experience for exhibitors and other attendees.
                            </p>
                            <p align="justify">According to the privacy statement provided to you during the registration
                                and login process, and as stipulated in the visitor regulations, when you enter virtual
                                spaces, areas, or rooms (such as booths), we will share your personal information with
                                sponsors and exhibitors to facilitate interaction with you.</p>
                            <p align="justify"><strong>&nbsp;</strong></p>
                            <p align="justify"><strong><em>Marketing</em></strong></p>
                            <p align="justify">We may use your personal information to send you newsletters, promotional
                                product information, or other marketing emails to keep you informed in real-time about our
                                developments, exhibitions, and products that may be of interest to you. Depending on the
                                nature of your interaction with us and applicable data protection laws, you may actively
                                consent (i.e., opt-in) to receive marketing communications from us, or we may have the right
                                to send you such information based on your consent to this privacy policy. You may choose to
                                opt out of receiving marketing communications thereafter and have the right to change your
                                marketing preferences at any time.</p>
                            <p align="justify">In certain cases, if we have a lawful basis and you have provided a personal
                                phone number, we may, in accordance with applicable data protection laws, send you marketing
                                messages or make marketing calls for marketing purposes. If we have a lawful basis and you
                                have provided a postal address, we may also mail letters containing information about new
                                products and events.</p>
                            <p align="justify">&nbsp;</p>
                            <p align="justify"><strong><u>The way w</u></strong><strong><u>e protect personal
                                        information</u></strong></p>
                            <p align="justify">&nbsp;</p>
                            <p align="justify">We are aware of the importance of protecting and managing personal
                                information. We will strive to handle all the personal information you provide in the most
                                cautious and secure manner possible. This section will introduce some of the current
                                security measures.</p>
                            <p align="justify">We employ various physical and technical measures to protect the security of
                                personal information and prevent unauthorized access, use, or disclosure of this
                                information. We store electronic data and databases in secure computer systems and control
                                access to the information through physical and electronic means. We have established
                                detailed security and data protection policies and provide data protection training for
                                employees, requiring them to comply with relevant regulations when handling personal
                                information.</p>
                            <p align="justify">&nbsp;</p>
                            <p align="justify"><strong>We only retain personal information for as long as necessary or as
                                    permitted by relevant laws.</strong></p>
                            <p align="justify">&nbsp;</p>
                            <p align="justify">As mentioned above, we retain personal information for the duration of its
                                use. We may continue to use the information for certain legitimate business purposes after
                                its use has ceased. For example, if you choose to no longer receive our marketing
                                information, we will retain only limited information related to you to ensure that we comply
                                with your request to not receive information. Additionally, we may continue to retain your
                                personal information in order to comply with legal requirements, protect or exercise our
                                legal rights.</p>
                            <p align="justify">&nbsp;</p>
                            <p align="justify">When determining the appropriate retention period for personal information,
                                we take into account the amount, nature, and sensitivity of the personal information, the
                                risk of unauthorized use or disclosure of your personal information, the purposes for which
                                we process your personal information and whether we can achieve those purposes through other
                                means, and the applicable legal requirements. Upon expiration of the aforementioned
                                retention period, unless we obtain your explicit consent, we will delete your relevant
                                personal information or anonymize it.</p>
                            <p align="justify">&nbsp;</p>
                            <p align="justify"><strong><u>Contact Us</u></strong></p>
                            <p align="justify">&nbsp;</p>
                            <p align="justify">If you have any questions regarding this privacy policy, please contact:</p>
                            <p align="justify">Personal Information Protection Team</p>
                            <p align="justify">Email: <a href="mailto:admin@solart.pro" data-mce-href="mailto:admin@solart.pro">admin@solart.pro</a></p>
                            <p align="justify">&nbsp;</p>
                            <p align="justify">In general, we will respond within 15 working days. If you are not satisfied
                                with our response, especially if our handling of information has infringed upon your legal
                                rights and interests, you may also file a complaint or report to regulatory authorities such
                                as the Cyberspace Administration, Telecommunications Administration, Public Security Bureau,
                                or the Administration for Industry and Commerce.</p>
                        </div>
                    </div>
                </div>
                <div class="news-details-top-navigation-bar">
                    <div class="news-details-top-navigation-bar-left-logo">
                        <img alt="logo6457" src="/logo%201-1500h.png" class="news-details-logo" />
                    </div>
                    <BottomBar />
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import NavbarInteractive from "../components/navbar-interactive";
import BottomBar from "../components/bottomBar.vue";

export default {
    name: "PrivatePolicy",
    components: {
        NavbarInteractive,
        BottomBar,
    },
    data() {
        return {
            newsId: 0,
            newsTitle: "",
            summary: "",
            parsedHTML: "",
            published_time: "",
        };
    },
    metaInfo: {
        title: "Private Policy - SOLART素鳞科技",
        meta: [
            {
                property: "og:title",
                content: "Private Policy - SOLART素鳞科技",
            },
        ],
    },
};
</script>
  
<style>
.news-details-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
}

.content p {
    line-height: 1.6;
    margin-bottom: 10px;
}

.news-details-frame {
    gap: 10px;
    width: 100%;
    height: auto;
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    flex-shrink: 0;
    background-color: rgba(26, 26, 26, 1);
}

.news-details-page-content {
    margin-top: 40px;
    gap: 39px;
    width: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
}

.news-details-hero-image {
    gap: 10px;
    height: auto;
    display: flex;
    padding: 22px 0;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
    background-size: cover;
    justify-content: space-between;
}

.news-details-news-container {
    gap: 20px;
    width: 80%;
    height: auto;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    min-height: 80vh;
    color: #fff;
}

.news-details-frame13 {
    gap: 40px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.news-details-frame3 {
    gap: 21px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.news-details-text {
    color: rgba(255, 255, 255, 1);
    width: 75%;
    height: auto;
    font-size: 24px;
    font-style: Bold;
    text-align: center;
    font-family: Noto Sans SC;
    font-weight: 700;
    line-height: 35px;
    font-stretch: normal;
    text-decoration: none;
}

.news-details-text01 {
    color: rgba(255, 255, 255, 1);
    width: 775px;
    height: auto;
    opacity: 0.8;
    font-size: 16px;
    text-align: center;
    font-family: Noto Sans SC;
    line-height: 25px;
    font-stretch: normal;
    text-decoration: none;
}

.news-details-text05 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    opacity: 0.6;
    font-size: 16px;
    text-align: center;
    font-family: Noto Sans SC;
    line-height: 25px;
    font-stretch: normal;
    text-decoration: none;
}

.content {
    width: 100%;
}

.imgcontent {
    width: 100%;
    height: 500px;
    max-width: 1002px;
    object-fit: contain;
    border-radius: 10px;
}

.pcontent {
    color: rgb(255, 255, 255);
    height: auto;
    opacity: 0.8;
    font-size: 14px;
    max-width: 1002px;
    font-style: Medium;
    text-align: left;
    font-family: Noto Sans SC;
    font-weight: 500;
    line-height: 25.2px;
    font-stretch: normal;
    text-decoration: none;
}

.news-details-text08 {
    color: rgb(255, 255, 255);
    height: auto;
    opacity: 0.8;
    font-size: 14px;
    max-width: 1002px;
    font-style: Medium;
    text-align: left;
    font-family: Noto Sans SC;
    font-weight: 500;
    line-height: 25.2px;
    font-stretch: normal;
    text-decoration: none;
}

.h1title {
    color: rgb(255, 255, 255);
    border-left: 4px solid #016ded;
    padding-left: 10px;
    width: 100%;
    height: auto;
    opacity: 0.8;
    font-size: 16px;
    max-width: 1002px;
    font-style: Medium;
    text-align: left;
    font-family: Noto Sans SC;
    font-weight: 500;
    line-height: 28.8px;
    font-stretch: normal;
    text-decoration: none;
}

.news-details-text10 {
    color: rgb(255, 255, 255);
    height: auto;
    opacity: 0.8;
    font-size: 14px;
    max-width: 1002px;
    font-style: Medium;
    text-align: left;
    font-family: Noto Sans SC;
    font-weight: 500;
    line-height: 25.2px;
    font-stretch: normal;
    text-decoration: none;
}

.news-details-placeholder3 {
    width: 100%;
    height: 500px;
    max-width: 1002px;
    object-fit: contain;
    border-radius: 10px;
}

.news-details-text11 {
    color: rgb(255, 255, 255);
    height: auto;
    opacity: 0.8;
    font-size: 14px;
    max-width: 1002px;
    font-style: Medium;
    text-align: left;
    font-family: Noto Sans SC;
    font-weight: 500;
    line-height: 25.2px;
    font-stretch: normal;
    text-decoration: none;
}

.news-details-top-navigation-bar {
    gap: 10px;
    display: flex;
    padding: 10px 30px 25px;
    overflow: hidden;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
}

.news-details-top-navigation-bar-left-logo {
    gap: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.news-details-logo {
    width: 250px;
    height: 64px;
}

.news-details-top-navigation-bar-right-links {
    gap: 38px;
    display: flex;
    opacity: 0.8;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.news-details-text12 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 18px;
    font-style: Bold;
    text-align: left;
    font-family: Noto Sans SC;
    font-weight: 700;
    line-height: 25.200000762939453px;
    font-stretch: normal;
    text-decoration: none;
}

.news-details-text14 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 18px;
    font-style: Bold;
    text-align: left;
    font-family: Noto Sans SC;
    font-weight: 700;
    line-height: 25.200000762939453px;
    font-stretch: normal;
    text-decoration: none;
}

.news-details-text16 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 18px;
    font-style: Bold;
    text-align: left;
    font-family: Noto Sans SC;
    font-weight: 700;
    line-height: 25.200000762939453px;
    font-stretch: normal;
    text-decoration: none;
}

@media (max-width: 991px) {
    .news-details-news-container {
        position: relative;
    }

    .news-details-frame13 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .news-details-text {
        color: rgb(255, 255, 255);
        width: 100%;
        font-size: 24px;
        font-family: Noto Sans SC;
        font-weight: 700;
        line-height: 33.6px;
        text-decoration: none;
    }

    .news-details-text01 {
        color: rgb(255, 255, 255);
        width: 100%;
        font-size: 16px;
        font-family: Noto Sans SC;
        font-weight: 700;
        line-height: 22.4px;
        text-decoration: none;
    }
}

@media (max-width: 767px) {
    .news-details-news-container {
        width: 96%;
    }

    .news-details img {
        max-width: 100%;
    }

    .news-details-frame13 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .news-details-text {
        font-size: 18px;
        line-height: 1.6;
    }

    .news-details-text01 {
        font-size: 14px;
        line-height: 1.6;
    }

    .news-details-sample-img1 {
        height: auto;
    }

    .news-details-placeholder3 {
        height: auto;
    }

    .news-details-logo {
        width: auto;
        height: 2rem;
    }

    .news-details-text12 {
        font-size: 16px;
    }

    .news-details-text14 {
        font-size: 16px;
    }

    .news-details-text16 {
        font-size: 16px;
    }
}</style>
  