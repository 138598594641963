<template>
  <div class="news-details-container" id="page-container">
    <navbar-interactive v-bind:defaultBlack="true"></navbar-interactive>
    <div class="news-details-frame">
      <div class="news-details-page-content">
        <div class="news-details-hero-image"></div>
        <div class="news-details-news-container">
          <div class="news-details-frame13">
            <div class="news-details-frame3">
              <span class="news-details-text">
                {{ newsTitle }}
              </span>
              <span class="news-details-text01">
                <span>
                  {{ summary }}
                </span>
              </span>
              <span class="news-details-text05"
                ><span>{{ published_time }}</span></span
              >
            </div>
            <div class="news-details" id="news-details">
              <div v-html="parsedHTML"></div>
            </div>
          </div>
        </div>
        <div class="news-details-top-navigation-bar">
          <div class="news-details-top-navigation-bar-left-logo">
            <img alt="logo6457" src="/logo%201-1500h.png" class="news-details-logo" />
          </div>
          <BottomBar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarInteractive from "../components/navbar-interactive";
import BottomBar from "../components/bottomBar.vue";
import he from "he";

export default {
  name: "NewsDetails",
  components: {
    NavbarInteractive,
    BottomBar,
  },
  data() {
    return {
      newsId: 0,
      newsTitle: "",
      summary: "",
      parsedHTML: "",
      published_time: "",
    };
  },
  created() {
    //read the news id from the url
    var vm = this;
    var newsID = this.$route.params.id;
    this.newsId = newsID;
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch("https://solart.pro/portal/content/?id=" + newsID, requestOptions)
      .then((response) => response.json()) // Use response.json() to parse the JSON
      .then((data) => {
        //console.log(newsID)
        // Find the selected news based on the newsID
        const selectedNews = data.data.find((news) => news.id === newsID);

        // If the selected news is not found, redirect to /404
        if (!selectedNews) {
          this.$router.push("/404");
          return;
        }

        // Continue with the rest of the code
        // ...

        vm.published_time = selectedNews.time;
        const newsTitle = selectedNews.title;
        vm.newsTitle = newsTitle;
        vm.summary = selectedNews.excerpt;
        vm.parsedHTML = selectedNews.content;
      })
      .catch((error) => console.error(error));
  },
  methods: {
    
  },
  metaInfo: {
    title: "NewsDetails - SOLART素鳞科技",
    meta: [
      {
        property: "og:title",
        content: "NewsDetails - SOLART素鳞科技",
      },
    ],
  },
};
</script>

<style>
.news-details-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.news-details p {
  text-indent: 1em;
  line-height: 1.6;
  margin-bottom: 15px;
}
.news-details p img {
  margin: 0 auto;
}
.news-details-frame {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(26, 26, 26, 1);
}
.news-details-page-content {
  margin-top: 40px;
  gap: 39px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.news-details-hero-image {
  gap: 10px;
  height: auto;
  display: flex;
  padding: 22px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
}
.news-details-news-container {
  gap: 20px;
  width: 80%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  min-height: 80vh;
  color: #fff;
}
.news-details-frame13 {
  gap: 40px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.news-details-frame3 {
  gap: 21px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.news-details-text {
  color: rgba(255, 255, 255, 1);
  width: 75%;
  height: auto;
  font-size: 24px;
  font-style: Bold;
  text-align: center;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 35px;
  font-stretch: normal;
  text-decoration: none;
}
.news-details-text01 {
  color: rgba(255, 255, 255, 1);
  width: 775px;
  height: auto;
  opacity: 0.8;
  font-size: 16px;
  text-align: center;
  font-family: Noto Sans SC;
  line-height: 25px;
  font-stretch: normal;
  text-decoration: none;
}
.news-details-text05 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  opacity: 0.6;
  font-size: 16px;
  text-align: center;
  font-family: Noto Sans SC;
  line-height: 25px;
  font-stretch: normal;
  text-decoration: none;
}
.news-details {
  gap: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.imgcontent {
  width: 100%;
  height: 500px;
  max-width: 1002px;
  object-fit: contain;
  border-radius: 10px;
}
.pcontent {
  color: rgb(255, 255, 255);
  height: auto;
  opacity: 0.8;
  font-size: 14px;
  max-width: 1002px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 25.2px;
  font-stretch: normal;
  text-decoration: none;
}
.news-details-text08 {
  color: rgb(255, 255, 255);
  height: auto;
  opacity: 0.8;
  font-size: 14px;
  max-width: 1002px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 25.2px;
  font-stretch: normal;
  text-decoration: none;
}
.h1title {
  color: rgb(255, 255, 255);
  border-left: 4px solid #016ded;
  padding-left: 10px;
  width: 100%;
  height: auto;
  opacity: 0.8;
  font-size: 16px;
  max-width: 1002px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 28.8px;
  font-stretch: normal;
  text-decoration: none;
}
.news-details-text10 {
  color: rgb(255, 255, 255);
  height: auto;
  opacity: 0.8;
  font-size: 14px;
  max-width: 1002px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 25.2px;
  font-stretch: normal;
  text-decoration: none;
}
.news-details-placeholder3 {
  width: 100%;
  height: 500px;
  max-width: 1002px;
  object-fit: contain;
  border-radius: 10px;
}
.news-details-text11 {
  color: rgb(255, 255, 255);
  height: auto;
  opacity: 0.8;
  font-size: 14px;
  max-width: 1002px;
  font-style: Medium;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 500;
  line-height: 25.2px;
  font-stretch: normal;
  text-decoration: none;
}
.news-details-top-navigation-bar {
  gap: 10px;
  display: flex;
  padding: 10px 30px 25px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.news-details-top-navigation-bar-left-logo {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.news-details-logo {
  width: 250px;
  height: 64px;
}
.news-details-top-navigation-bar-right-links {
  gap: 38px;
  display: flex;
  opacity: 0.8;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.news-details-text12 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.news-details-text14 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.news-details-text16 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
@media (max-width: 991px) {
  .news-details-news-container {
    position: relative;
  }
  .news-details-frame13 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .news-details-text {
    color: rgb(255, 255, 255);
    width: 100%;
    font-size: 24px;
    font-family: Noto Sans SC;
    font-weight: 700;
    line-height: 33.6px;
    text-decoration: none;
  }
  .news-details-text01 {
    color: rgb(255, 255, 255);
    width: 100%;
    font-size: 16px;
    font-family: Noto Sans SC;
    font-weight: 700;
    line-height: 22.4px;
    text-decoration: none;
  }
}
@media (max-width: 767px) {
  .news-details-news-container {
    width: 96%;
  }
  .news-details img{
    max-width: 100%;
  }
  .news-details-frame13 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .news-details-text {
    font-size: 18px;
    line-height: 1.6;
  }
  .news-details-text01 {
    font-size: 14px;
    line-height: 1.6;
  }
  .news-details-sample-img1 {
    height: auto;
  }
  .news-details-placeholder3 {
    height: auto;
  }
  .news-details-logo {
    width: auto;
    height: 2rem;
  }
  .news-details-text12 {
    font-size: 16px;
  }
  .news-details-text14 {
    font-size: 16px;
  }
  .news-details-text16 {
    font-size: 16px;
  }
}
</style>
