<template>
  <div class="huahu-kids-container" id="page-container">
    <navbar-interactive
            rootClassName="navbar-interactive-root-class-name3"
          ></navbar-interactive>
    <div class="huahu-kids-frame">
      <div class="huahu-kids-page-content">
        <div class="huahu-kids-hero-image">

          <div class="huahu-kids-logo-description">
            <div class="huahu-kids-description">
              <img
                alt="image"
                src="/logos/huahuakids.svg"
                class="huahu-kids-image"
              />
              <div class="title_container">
                <span class="huahu-kids-text">AIGC Creative Aesthetic Education</span>
                <span class="huahu-kids-text01">
                  AIGC Youth Digital Creative Education
                </span>
              </div>
              <!-- <app-btndefault
                :isDisabled = "true"
                text="Stay Tuned"
                rootClassName="btndefault-root-class-name"
              ></app-btndefault> -->
            </div>
          </div>
          <div class="huahu-kids-mottos">
            <img
              alt="bottomArrow6397"
              src="/bottomarrow.svg"
              class="huahu-kids-bottom-arrow"
            />
          </div>
        </div>
        <div>
          <product-introduction-cardv2
            image_src="/Placeholder 1 (9).png"
            mainTitle="HUAHUA Kids: AIGC aesthetic education for children and teenagers"
            subTitle="By allowing children to customize the style, color, character and other elements of the image, the generated image is personalized to cultivate children's aesthetic and decision-making skills. Through the audio interaction between Chinese and English, children can interact with the HUAHUA Kids by speaking, and create unique and exclusive paintings while practicing language, which is more interesting. By setting up a triple filtering model, sensitive words in text descriptions are automatically detected, and inappropriate images are filtered and masked, making learning more secure. By integrating knowledge points such as common sense science popularization and creativity cultivation, it stimulates children's imagination and creativity, and accompanies them to grow up healthy and happy. This can make entertaining creations both inspiring and we expect every child to have a different experience and become the prompt engineer of the world in the future."
          ></product-introduction-cardv2>
          <product-introduction-cardv2
            reverse
            image_src="Placeholder_huahuakids.png"
            mainTitle='HUAHUA AI STUDIO: Instantly Generate "Art Masterpieces" with Doodles'
            subTitle='
              Cultivate AI innovation thinking and create a new era of art education for teenagers, customized offline digital art enlightenment classes. Doodle casually, upload sketches, and instantly generate "art masterpieces" with mature AI painting styles, such as "Monet Impressionism," "Chinese Ink Painting," "Freehand Light Color," and more... Various AI style models are continuously updated.
              <br/>In addition to AI digital art paintings, there are also cultural and creative product production experiences, allowing children to "create from 0 to 1" and turn their works into "unique" exclusive cultural and creative products. "Aesthetic ability," "imagination," "creativity," and "interdisciplinary thinking," using interests as a guide to cultivate children&apos;s comprehensive development of multidimensional abilities.
              <br/>Offline experience locations:
              <br/>HUAHUA AI STUDIO: 1F Cultural and Creative Space, New Guangzhou Art Expo Academy
              <br/>AI Artistic Creative Thinking - Study Camp: New Building of Guangzhou Academy of Fine Arts (Guangzhou Art Museum)
            '
          ></product-introduction-cardv2>
        </div>
        <!-- <span class="home-text06 hidden"><span>产品特色</span></span>
        <product-carousel class="hidden" :image_src="caresouleImgs"></product-carousel> -->
        <div class="huahu-kids-top-navigation-bar">
          <div class="huahu-kids-top-navigation-bar-left-logo">
            <img
              alt="logo6457"
              src="/logo%201-1500h.png"
              class="huahu-kids-logo"
            />
          </div>
          <bottom-bar></bottom-bar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarInteractive from '../components/navbar-interactive'
import AppBtndefault from '../components/btndefault'
import ProductIntroductionCardv2 from '../components/product-introduction-cardv2'
import productCarousel from '../components/productCarousel.vue'
import BottomBar from '../components/bottomBar.vue'

export default {
  name: 'HUAHUAKids',
  components: {
    NavbarInteractive,
    AppBtndefault,
    ProductIntroductionCardv2,
    productCarousel,
    BottomBar
  },
  data(){
    BottomBar
    return{
      caresouleImgs: [
        '/exampleSlide1.png',
        '/exampleSlide2.png',
        '/exampleSlide3.png',
      ],
    }
  },
  metaInfo: {
    title: 'HUAHUA Kids - SOLART素鳞科技',
    meta: [
      {
        property: 'og:title',
        content: 'HUAHUA Kids - SOLART素鳞科技',
      },
    ],
  },
}
</script>

<style scoped>
.home-text06 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  opacity: 0.80;
  font-size: 24px;
  font-style: 85 Bold;
  text-align: justified;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 33.599998474121094px;
  font-stretch: normal;
  text-decoration: none;
}
.huahu-kids-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.huahu-kids-frame {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(26, 26, 26, 1);
}
.huahu-kids-page-content {
  gap: 39px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  min-height: 100vh;

}
.huahu-kids-hero-image {
  gap: 10px;
  height: 900px;
  display: flex;
  padding: 22px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0.00%,rgba(0, 0, 0, 0.4) 100.00%),url("/huahuakids.png");
  background-position-x: 32%;
}
.huahu-kids-logo-description {
  gap: 40px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.huahu-kids-description {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.huahu-kids-image {
  width: 160px;
  height: 160px;
  object-fit: cover;
}
.huahu-kids-text {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 64px;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 64px;
  font-stretch: normal;
  text-decoration: none;
}
.huahu-kids-text01 {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 2rem;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 36px;
  font-stretch: normal;
  letter-spacing: 12px;
  text-decoration: none;
}
.title_container{
  display: flex; 
  flex-direction: column;
  gap: 18px;
  max-width: 95vw;
}
@media (max-width: 956px) {
  .huahu-kids-text {
    font-size: 48px !important;
    line-height: 38px !important;
  }
  .huahu-kids-text01{
    font-size: 20px !important;
    line-height: 6px;
    letter-spacing:normal !important;
  }
  .title_container{
  display: flex; 
  flex-direction: column;
  gap: 2px;
}

}
@media (max-width: 541px) {
  .huahu-kids-text {
    font-size: 30px !important;
    line-height: 38px !important;
  }
  .huahu-kids-text01{
    font-size: 20px !important;
    line-height: 6px;
    letter-spacing:normal !important;
  }
  .title_container{
  display: flex; 
  flex-direction: column;
  gap: 2px;
}

}

@media (max-width: 374px) {
  .huahu-kids-text {
    font-size: 26px !important;
    line-height: 38px !important;
  }
  .huahu-kids-text01{
    font-size: 20px !important;
    line-height: 6px;
    letter-spacing:normal !important;
  }
  .title_container{
  display: flex; 
  flex-direction: column;
  gap: 2px;
}

}

.huahu-kids-mottos {
  gap: 9px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
}
.huahu-kids-bottom-arrow {
  width: 16px;
  height: 8px;
}
.huahu-kids-top-navigation-bar {
  gap: 10px;
  display: flex;
  padding: 10px 30px 25px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.huahu-kids-top-navigation-bar-left-logo {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.huahu-kids-logo {
  width: 250px;
  height: 64px;
}
.huahu-kids-top-navigation-bar-right-links {
  gap: 38px;
  display: flex;
  opacity: 0.80;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.huahu-kids-text05 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.huahu-kids-text07 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.huahu-kids-text09 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 991px) {
  .huahu-kids-text {
    color: rgb(255, 255, 255);
    font-size: 64px;
    font-weight: 700;
    line-height: 64px;
    text-decoration: none;
  }
  .huahu-kids-text01 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 12px;
    text-decoration: none;
  }
  .huahu-kids-text02 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 12px;
    text-decoration: none;
  }
  .huahu-kids-text03 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 12px;
    text-decoration: none;
  }
  .huahu-kids-text04 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 12px;
    text-decoration: none;
  }
}
@media(max-width: 767px) {
  .huahu-kids-text01 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .huahu-kids-text02 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .huahu-kids-text03 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .huahu-kids-text04 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .huahu-kids-logo {
    width: auto;
    height: 2rem;
  }
  .huahu-kids-text05 {
    font-size: 16px;
  }
  .huahu-kids-text07 {
    font-size: 16px;
  }
  .huahu-kids-text09 {
    font-size: 16px;
  }
}
</style>
