<template>
  <div class="huahua-container" id="page-container">
    <navbar-interactive
            rootClassName="navbar-interactive-root-class-name2"
          ></navbar-interactive>
    <div class="huahua-frame">
      <div class="huahua-page-content">
        <div class="huahua-hero-image">

          <div class="huahua-logo-description">
            <div class="huahua-description">
              <img alt="image" src="/logos/huahua.svg" class="huahua-image" />
              <div class="title_container">
              <span class="huahua-text">HUAHUA</span>
              <span class="huahua-text1">Made the Creation Easy</span>
            </div>
              <a
                href="http://huahua.solart.pro"
                target="_blank"
                rel="noreferrer noopener"
                class="huahua-link"
              >
                <app-btndefault
                  text="ENTER"
                  rootClassName="btndefault-root-class-name1"
                  class="huahua-component1"
                ></app-btndefault>
              </a>
            </div>
          </div>
          <div class="huahua-mottos">
            <img
              alt="bottomArrow6397"
              src="/bottomarrow.svg"
              class="huahua-bottom-arrow"
            />
          </div>
        </div>
        <div>
          <product-introduction-cardv2 
            mainTitle="An AI Drawing Platform that understands Chinese Culture and Asian Aesthetics"
            subTitle="Support bilingual Chinese and English, helping users in painting, entertainment, consumption, industry and other fields of visual applications. There are 20 self-developed AI engine engines, including a number of unique Chinese style AI engines, which can realize a variety of advanced functions such as image generated from one sentence or one picture and also including image stylization , sketching changing to rendering,etc."
            image_src="/Placeholder 1 (7).png"
          ></product-introduction-cardv2>
        </div>
        <!-- <span class="home-text06 hidden"><span>产品特色</span></span>
        <product-carousel class="hidden" :image_src="caresouleImgs"></product-carousel> -->
        <div class="huahua-top-navigation-bar">
          <div class="huahua-top-navigation-bar-left-logo">
            <img alt="logo6457" src="/logo%201-1500h.png" class="huahua-logo" />
          </div>
          <bottom-bar></bottom-bar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarInteractive from '../components/navbar-interactive'
import AppBtndefault from '../components/btndefault'
import ProductIntroductionCardv2 from '../components/product-introduction-cardv2'
import productCarousel from '../components/productCarousel.vue'
import BottomBar from '../components/bottomBar.vue'

export default {
  name: 'HUAHUA',
  components: {
    NavbarInteractive,
    AppBtndefault,
    ProductIntroductionCardv2,
    productCarousel,
    BottomBar 
  },
  data(){

       return{
      caresouleImgs: [
        '/exampleSlide1.png',
        '/exampleSlide2.png',
        '/exampleSlide3.png',
      ],
    }
  },
  metaInfo: {
    title: 'HUAHUA - SOLART素鳞科技',
    meta: [
      {
        property: 'og:title',
        content: 'HUAHUA - SOLART素鳞科技',
      },
    ],
  },
}
</script>

<style scoped>


.home-text06 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  opacity: 0.80;
  font-size: 24px;
  font-style: 85 Bold;
  text-align: justified;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 33.599998474121094px;
  font-stretch: normal;
  text-decoration: none;
}
.huahua-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.huahua-frame {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(26, 26, 26, 1);
}
.huahua-page-content {
  gap: 39px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  min-height: 100vh;

}
.huahua-hero-image {
  gap: 10px;
  height: 900px;
  display: flex;
  padding: 22px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0.00%,rgba(0, 0, 0, 0.4) 100.00%),url("/huahuaCover.png");
  background-position: center;
}
.huahua-logo-description {
  gap: 40px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.huahua-description {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.huahua-image {
  width: 160px;
  height: 160px;
  object-fit: cover;
}
.title_container{
  display: flex; 
  flex-direction: column;
  gap: 18px;
}
.huahua-text {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 64px;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 64px;
  font-stretch: normal;
  text-decoration: none;
}

  

.huahua-text1 {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 2rem;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 36px;
  font-stretch: normal;
  letter-spacing: 12px;
  text-decoration: none;
}
@media (max-width: 457px) {
  .huahua-text {
    font-size: 32px;
    line-height: 48px;
  }
  .huahua-text1{
    font-size: 20px !important;
    line-height: 36px;
    letter-spacing: normal !important;
  }
  .title_container{
  display: flex; 
  flex-direction: column;
  gap: 2px;}
}
.huahua-link {
  display: contents;
}
.huahua-component1 {
  text-decoration: none;
}
.huahua-mottos {
  gap: 9px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
}
.huahua-bottom-arrow {
  width: 16px;
  height: 8px;
}
.huahua-top-navigation-bar {
  gap: 10px;
  display: flex;
  padding: 10px 30px 25px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.huahua-top-navigation-bar-left-logo {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.huahua-logo {
  width: 250px;
  height: 64px;
}
.huahua-top-navigation-bar-right-links {
  gap: 38px;
  display: flex;
  opacity: 0.80;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.huahua-text2 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.huahua-text4 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.huahua-text6 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 767px) {
  .huahua-text1 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .huahua-logo {
    width: auto;
    height: 2rem;
  }
  .huahua-text2 {
    font-size: 16px;
  }
  .huahua-text4 {
    font-size: 16px;
  }
  .huahua-text6 {
    font-size: 16px;
  }
}
</style>
